import bsale from '../Bsale'
import { cart, getCart } from './cart'
import { collection, addToCartCollection, paintCollectionsProductsLazyLoad, setProductsToContainer } from './collection'
import { dropdownHeight, stickyHeader, searchHidden, hoverHeader, headerSearch, dropdownMultiLevel } from './common/header'

import { navigationSupport } from './common/support'

import { loadVideo, loadMap, initSlider, offerAlert, inicioAlert, contactPhone, contactChat, setCustomFunction } from '../util'
import { checkout } from './checkout'
import { form } from './form'
import { home } from './home'
import { product } from './product'

// its necessary to execute the code with a load event listener, due to owlcarousel loads later :/
window.addEventListener('load', () => {
  const [path, slug] = location.pathname.substr(1).split('/')

  console.log(`%cBsale %cVersión ${process.env.VERSION} %cRevisa documentación en %chttps://github.com/Bsale-IO/template-docs/wiki`,
              'padding:3px;color:#fff;background-color:#ff6815',
               'padding:3px 8px;color:black;background-color:silver',
              'padding:3px',
              'padding:3px; color:cyan'
   );
  //estas se agregan a todas las paginas
  navigationSupport();
  loadVideo();
  loadMap();
  getCart();
  initSlider();
  offerAlert();
  contactPhone();
  contactChat();
  stickyHeader();
  hoverHeader();
  searchHidden();
  headerSearch();
  dropdownMultiLevel();
  setCustomFunction(setProductsToContainer);
  inicioAlert();
  /* new */dropdownHeight();
  /* new */ addToCartCollection();
  
  // se asignan segun url 
  switch (path) {
    case '':
      home()
      paintCollectionsProductsLazyLoad();
      break
    case 'product':
      product(slug)
      break
    case 'cart':
      if (bsale.cart.items) {
        cart(slug)
      }
      break
    case 'collection':
    case 'search':
    case 'brand':
    case 'products':
      collection(slug)
      break
    case 'checkout':
      checkout(slug)
      break
    case 'form':
      form()
      break
  }

  // lazy fontawesome load
  const link = document.createElement('link')
  link.href = 'https://dojiw2m9tvv09.cloudfront.net/fontawesome/css/all.min.css'
  link.rel = 'stylesheet'
  document.head.appendChild(link)
  console.log(`%cFontawesome %c5.15.4 %chttps://fontawesome.com/`,
              'padding:3px;color:#fff;background-color:#339AF0',
              'padding:3px 8px;color:black;background-color:silver',
              'padding:3px',
              )
   
  
  //new font awesome
  // const fa = document.createElement("script")
  // fa.src = "https://kit.fontawesome.com/c297a0c7fb.js";
  // fa.crossorigin="anonymous";
  // document.head.appendChild(fa);
  // console.log(`%cFontawesome %c5.15.1 %chttps://fontawesome.com/`,
  //             'padding:3px;color:#fff;background-color:#339AF0',
  //             'padding:3px 8px;color:black;background-color:silver',
  //             'padding:3px',
  //             )
})
