import { addToCart } from './addToCart'
import { removeFromCart } from './removeFromCart'
import { viewProduct } from './viewProduct'
import { search } from './search'
import { formSent } from './formSent'
import { checkout } from './checkout'
import { purchase } from './purchase'

export default {
  addToCart,
  removeFromCart,
  viewProduct,
  search,
  formSent,
  checkout,
  purchase
}
