import { getAttributeValue } from '../util/helper'
import { CartItem } from './CartItem'
import { get } from './get'
import { log } from './log'
import { Variant } from './Variant'

export const itemDuplicated = { item: null, isDuplicated: false }
export const cartContent = { html: '', ctName: '' };
export class Cart {

  type: number
  items: number
  total: number
  detail: CartItem[]
  created?: number
  

  constructor({ items = 0, total = '0', detail = [], created }: {
    items: number
    total: string
    detail?: {
      id: number
      quantity: number
      [key: string]: any
    }[]
    created?: number
  }) {
    this.items = items
    this.total = parseFloat(total)
    this.detail = detail && detail.map(({ id, quantity, ...variant }) => new CartItem({ id, quantity, variant }))
    this.created = created
  }

  async get(): Promise<void> {
    try {

      let ctName = getAttributeValue('[data-bs="cart.dropdown"]', 'data-component-name');

      if (!ctName) {
        ctName = 'noCart';
      }
      
      cartContent.ctName = ctName;
      const { cart, cartHTML } = await get(`/cart/get_total_cart/${ctName}`);
      cartContent.html = cartHTML;
      this.created = cart.created || 0
      this.items = cart.items || 0
      this.type = cart.type || 2
      this.total = cart.total || 0

      const dropdownCart = document.querySelector("[data-bs='cart.dropdown']");
      if (cart.type === 2 || (cart.type === 1 && dropdownCart)) {
      	this.detail = cart.detail.map(({ id, quantity, ...variant }) => new CartItem({ id, quantity, variant }))
      }
      
    }
    catch (error) {
      console.error(error)
    }
  }

  async add(variant: Variant, quantity: number): Promise<CartItem> {
    const detailIndex = this.detail.findIndex((dt: any) => dt.variant.variantId == variant.id);

    if (detailIndex >= 0) {
      itemDuplicated.item = this.detail[detailIndex];
      itemDuplicated.isDuplicated = true;
      
      const addButtonCartInputValue: HTMLInputElement = document.querySelector(`[data-bs='containerItemsCart'] [data-info="${ this.detail[detailIndex].id }"] [data-bs='cart.quantity']`);
      if (addButtonCartInputValue && addButtonCartInputValue.value > "1") {
        addButtonCartInputValue.value = (parseInt(addButtonCartInputValue.value) + quantity-1).toString();
      }    
      
      const addButtonCart: HTMLButtonElement = document.querySelector(`[data-bs='containerItemsCart'] [data-info="${ this.detail[detailIndex].id }"] [data-bs='cart.quantity.plus']`);
      
      if (addButtonCart) {
        this.detail[detailIndex].quantity += quantity;
        addButtonCart.click();
        return this.detail[detailIndex];
      }
      
    }

    itemDuplicated.item = null;
    itemDuplicated.isDuplicated = false;

    if (variant instanceof Variant) {
      log('New item:', variant, 'quantity:', quantity)
      const { cart, cartDetail: { productCartId, quantity: newQuantity, ...rest } } = await get(`/product/create/${variant.id}?q=${quantity}`)
      const newItem = new CartItem({
        id: productCartId,
        quantity: newQuantity,
        variant: { ...variant, ...rest }
      })
      this.items = cart.items
      this.total = cart.total
      this.detail = [...this.detail, newItem]
      
      log('Updated cart', this)
      return newItem
    }
    throw new Error('El nuevo item debe ser una instancia de Variant')
  }

  async update(item: CartItem, quantity: number): Promise<CartItem> {
    if (this.detail.indexOf(item) !== -1) {
      const { cart, cartDetail } = await get(`/cart/update_detail/${item.id}?q=${quantity}`)
      const updatedItem: CartItem = item.update(cartDetail)
      this.items = cart.items
      this.total = cart.total
      log('Updated cart', this)
      return updatedItem
    }
    throw new Error('El item no existe en el carro')
  }

  async remove(item: CartItem): Promise<CartItem> {
    if (this.detail.indexOf(item) !== -1) {
      const { cart, cartDetail } = await get('/cart/delete_detail/' + item.id)
      this.items = cart.items
      this.total = cart.total
      this.detail = this.detail.filter(itemToRemove => itemToRemove !== item)
      log('Updated cart', this)
      return item
    }
    throw new Error('El item no existe en el carro')
  }
}
