import { newCreateModal } from '../../util';

export function navigationSupport(){
    if(window.navigator.userAgent.indexOf('MSIE') > 0){
        let msg ='Hemos detectado que tu navegador no está actualizado, \npor favor actualiza tu navegador o utiliza uno más nuevo';
        try{
            newCreateModal("error", msg)
        }catch(ex){
            console.log("error modal", ex)
            alert(msg)
        }
    }; //10 o inferior    
}