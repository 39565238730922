import { get } from "./get"

export class Plugins {

  //Añadir al header los scripts de los plugins
  async mountPluginsScripts() {
    let plugins = await this.getPlugins();
    if (plugins && plugins.length > 0) {
      plugins.forEach(plugin => { 
        const script = document.createElement('script');
        script.setAttribute(
          'src',
          plugin.src
        );
        script.setAttribute(
          'settings',
          plugin.settings
        );
        document.head.appendChild(script);
      });
    }
  }

  // Obtener data de plugins desde el gateway del market
  async getPlugins() {
    try {
      let scope = window.location.pathname.split("/")[1];
      if (!scope) {
        scope = 'home'
      }
      let data = await get(`/gateway/plugins/${scope}`);
      return data.scripts
    } catch (error) {
      console.warn(error)
    }
  }

}