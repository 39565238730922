import { metrics } from '../Metrics'

export function checkout(action) {

  switch (action) {
    case 'success':
      const token = location.pathname.replace('/checkout/success/', '')
      if (sessionStorage.getItem('purchase') !== token) {
        metrics.purchase()
        sessionStorage.setItem('purchase', token)
      }
      break
    case 'fail':
      break
  }
}
