import { Collection } from './Collection'
import { log } from './log'
import { Variant, Attribute } from './Variant'
import { get } from "./get"

export class Product {

  id: number
  variantId: number
  variants: Variant[]
  attributes?: Attribute[]
  private _currentAttributes?: Attribute[]
  related?: Collection
  accessories?: Collection
  private subscriptions: {
    type: string
    callback: Function
  }[]
  private fetchedVariants: boolean

  constructor({ product, variants, attributes, related, accessories }: {
    product: any
    variants: any
    attributes?: Attribute[]
    related?: any
    accessories?: any
  }) {
    for (const key in product) {
      this[key] = product[key]
    }
    if (variants) {
      this.variants = variants.map(variant => new Variant({
        ...variant,
        productName: product.title,
        description: variant.descripcion_variante,
        name: `${product.title} ${variant.descripcion_variante}`
      }))
    }
    if (attributes) {
      this.attributes = []
      for (const key in attributes) {
        this.attributes.push(attributes[key])
      }
      this._currentAttributes = []
    }
    if (related) {
      this.related = new Collection(related)
    }
    if (accessories) {
      this.accessories = new Collection(accessories)
    }
    this.subscriptions = []
  }

  async getVariantsByAttribute(attribute: Attribute, value: string, { stock = false, reset = [] }: {
    stock?: boolean
    reset?: string[]
  }): Promise<Variant[]> {
    if (this.attributes.indexOf(attribute) !== -1) {
      if (reset) {
        this.resetAttributes(reset)
      }
      const [_attribute] = this._currentAttributes.filter(_attribute => _attribute.name === attribute.name)
      if (!_attribute) {
        this._currentAttributes.push({
          name: attribute.name,
          value
        })
      }
      else {
        _attribute.value = value
      }
      const filteredVariants = this.variants.filter(variant => {
        const hasAttributes = variant.filterByAttributes(this._currentAttributes)
        if (hasAttributes) {
          if (stock) {
            const variantStock = variant.getStock()
            if (variantStock > 0) {
              return true
            }
            return false
          }
          return true
        }
        return false
      })
     
      return filteredVariants
      
    }
    else {
      throw new Error('El producto debe contener el atributo')
    }
  }

  resetAttributes(names: string[]): boolean {
    if (names) {
      this._currentAttributes = this._currentAttributes.filter(attribute => {
        if (names.indexOf(attribute.name) === -1) {
          return true
        }
        return false
      })
    }
    else {
      this._currentAttributes = []
    }
    return true
  }

  getJSON() { }

  getHTML() { }

  subscribe(type, callback) {
    switch (type) {
      case 'stock':
        this.subscriptions = [...this.subscriptions, { type, callback }]
        callback(this.variants[0].stock)
        break
      default:
        throw new Error('Subscription not suported')
    }
  }

  getSubscription(type): false | Function {
    const subscription = this.subscriptions.filter(sub => sub.type === type)[0]
    if (subscription) {
      return subscription.callback
    }
    return false
  }

  async getVariants() {
    try {
      if (!this.fetchedVariants) {
        let data = await get('/product/find_product_variant_stocks/' + this.id);
        this.variants = data.map(v => new Variant(v))
        this.fetchedVariants = true
      }

      return this.variants

    } catch (error) {
      console.warn(error)
    }
  }
}
