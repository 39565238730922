// @ts-nocheck
import { get } from "./get"

export class Tracking {

  static promiseResolve;
  static config;

  static trackingLoaded = new Promise(function(resolve){
    Tracking.promiseResolve = resolve;
  });

  //Añadir al header los scripts de tracking
  async mountTrackingScripts() {
    let config = {
      pixel: false,
      analytics: ""
    }
    //Traer info de tracking
    let tracking_data = await this.getTrackingData()

    //Verifica si viene pixel id
    if (tracking_data && tracking_data.facebook_pixel_id) {
      if (window.fbq) {
        //Ya tiene cargado script de Facebook Pixel
        config.pixel = true
      } else {
        //No tiene cargado script de Facebook Pixel
        await this.loadFacebookPixelScript(tracking_data.facebook_pixel_id, tracking_data.session_id)
        config.pixel = true
      }
    }
    //Verifica si viene analytics id
    if (tracking_data && tracking_data.google_analytics_id) {
      if (window.gtag) {
        //Ya tiene cargado script de Google Analytics
        config.analytics = tracking_data.google_analytics_id
      } else {
        //No tiene cargado script de Google Analytics
        await this.loadGoogleAnalyticsScript(tracking_data.google_analytics_id)
        config.analytics = tracking_data.google_analytics_id
      }
    }
    Tracking.promiseResolve();
    Tracking.config = config;
  }

  //Obtener data de tracking
  async getTrackingData() {
    try {
      let data = await get(`/gateway/tracking_info`);
      return data
    } catch (error) {
      console.warn(error)
    }
  }

  //Facebook Pixel script
  async loadFacebookPixelScript(pixel_id: string, session_id: string) {
    let result;
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    //Se añade promise para esperar ejecución del script
    result = new Promise((resolve) => {
      t.addEventListener("load", () => {
        resolve();
      });
    });
    //==================================================
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', pixel_id, {'external_id': session_id});
    fbq('track', 'PageView');
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
    <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=${pixel_id}&ev=PageView&noscript=1"
    />
    `
    document.head.appendChild(noscript);
    return await result
  }

  //Google Analytics script
  async loadGoogleAnalyticsScript(analytics_id) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${analytics_id}`
    let result = new Promise((resolve) => {
      script.addEventListener("load", () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = () => {
          dataLayer.push(arguments);
        }
        window.gtag('js', new Date());
        window.gtag('config', analytics_id);
        resolve();
      });
    });
    document.head.appendChild(script);
    return await result
  }

}