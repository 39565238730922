import Bsale from "../../Bsale";
import { formatPrice } from '../../util/formatPrice';

export function viewProduct(product, defaultVariant) {
  console.log(defaultVariant)
  return {
    gtm: {
      event: "view_item",
      data: {
        ecommerce: {
          detail: {
            products: [{
              id: defaultVariant.sku,
              name: product.title,
              variant: defaultVariant.title,
              price: formatPrice(defaultVariant.finalPrice, true)
            }]
          }
        }
      }
    },
    gtmGA4: {
      event: "view_item",
      data: {
        ecommerce: {
          items: [{
            item_id: defaultVariant.sku,
            item_name: product.title,
            item_brand: product.brand ? product.brand.name : "",
            item_variant: defaultVariant.title,
            price: formatPrice(defaultVariant.finalPrice, true),
            currency: Bsale.config.currency.isoCode,
            quantity: 1
          }]
        }
      }
    },
    analytics: {
      event: 'view_item',
      data: {
        items: [{
          id: defaultVariant.sku,
          name: product.title,
          variant: defaultVariant.title,
          price: formatPrice(defaultVariant.finalPrice, true)
        }]
      }
    },
    GA4: {
      event: 'view_item',
      data: {
        transaction_id: Date.now().toString(),
        currency: Bsale.config.currency.isoCode,
        items: [{
          item_id: defaultVariant.sku,
          item_name: product.title,
          item_brand: product.brand ? product.brand.name : "",
          item_variant: defaultVariant.title,
          price: formatPrice(defaultVariant.finalPrice, true),
          currency: Bsale.config.currency.isoCode,
          quantity: 1
        }],
        value: formatPrice(defaultVariant.finalPrice, true)
      }
    },
    pixel: {
      event: 'ViewContent',
      event_id: Date.now().toString(),
      data: {
        content_ids: [defaultVariant.sku],
        content_type: 'product',
        content_name: defaultVariant.name,
        currency: Bsale.config.currency.isoCode,
        contents: [{
          id: defaultVariant.sku,
          quantity: 1,
          item_price: formatPrice(defaultVariant.finalPrice, true)
        }]
      }
    }
  }
}
