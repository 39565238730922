// Convierte un HTML string en un elemento HTML
export const ConvertStringToHTML = function (str) {
  try {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  } catch (error) {
    console.log('No DOM detected!');
    return '';
  }
 };
 
 // Obtiene el valor del atributo de un elmento del DOM
 export const getAttributeValue = (elementKey = '', attrName = 'value') => {
  if (elementKey === '') {
    return;
  }
  const element = document.querySelector(elementKey);
  // Evalua si existe elemento
  if (!element) {
    return;
  }
  return element.getAttribute(attrName);
 }