export function search(searchString) {
  return {
    gtm: {
      event: 'search',
      data: {
        searchString: searchString
      }
    },
    gtmGA4: {
      event: 'search',
      data: {
        searchString: searchString
      }
    },
    analytics: {
      event: 'search',
      data: {
        search_term: searchString
      }
    },
    GA4: {
      event: 'search',
      data: {
        transaction_id: Date.now().toString(),
        search_term: searchString
      }
    },
    pixel: {
      event: 'Search',
      event_id: Date.now().toString(),
      data: {
        search_string: searchString
      }
    }
  }
}
