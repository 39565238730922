import './polyfill'
import bsale from './Bsale'
import { metrics } from './Metrics'
import './pages'
import { Collection } from './Bsale/Collection'

interface INIT {
  cart: {
    items: number,
    total: string,
    detail: any[]
  }
  checkout?
  products: any[]
  collections: any[]
  config
}

declare global {
  namespace NodeJS {
    interface Global {
      __TEST_GLOBAL__: {
        market: string
        cookies: string
        INIT: INIT
      }
    }
  }
  interface Window {
    INIT: INIT
  }
}

window.Bsale = bsale
window.Metrics = metrics
window.Collection = Collection
delete window.INIT
