import { Product } from './Product'

export class Collection {

  name: string
  items: Product[]

  constructor({ name, items }: {
    name: string
    items: any[]
  }) {
    this.name = name
    this.items = items.map(product => new Product({ product, variants: [{ id: product.variantId }] }))
  }

  getJSON() { }

  getHTML() { }
}
