import { clearHTML } from '../util'

export function home() {
  
  function showModal(m) {
    const modal = $(m)
    const content = modal.find('.modal-body').text().trim();
    const contentHTML = clearHTML(content).toLowerCase().replace(/\s/gm, " ")
    let delay;
    let show;
    if(modal.data("info")){
      delay = modal.data("info").delay * 1000;
      show = modal.data("info").show.toLowerCase()
    }
    const modalBody = document.querySelector('.modal-body')
    const imgModalBody = modalBody.querySelectorAll('img')
    const iframeModalBody = modalBody.querySelectorAll('iframe')
    const contentModal = content.length > 0 || imgModalBody.length > 0 || iframeModalBody.length > 0

    if (localStorage["content-modal"] != contentHTML && contentHTML != "") {
      modal.delay(5000).modal('show')
    } else {
      switch (show) {
        case "day":
        
          let now = new Date
          let past = localStorage.getItem("openModal") && new Date(parseInt(localStorage.getItem("openModal")))
          now = new Date(now.getFullYear(), now.getMonth(), now.getDate());

          if (past) {
            past = new Date(past.getFullYear(), past.getMonth(), past.getDate());
          }
          if ((now > past) && (contentModal)) {
            modal.delay(5000).modal('show');
          }

          localStorage.setItem("openModal", Date.now())
          break;

        case "once":
       
          var isshow = localStorage.getItem('isshow')
          if (localStorage["content-modal"] != contentHTML) {
            localStorage.removeItem("isshow");
          }

          if ((isshow == null) && (contentModal) ) {
            localStorage.setItem('content-modal', contentHTML);
            modal.delay(500).modal('show');
          }
          break;

        default:
     
          if (contentModal) {
            modal.delay(5000).modal('show');
          }
          break;
      }
    }

    localStorage.setItem('content-modal', contentHTML);
    localStorage.setItem('isshow', '1');

    //CANTIDAD DE TIEMPO QUE DEMORA EL MODAL EN CERRAR
    if (delay >= 1000) {
      setTimeout(function () {
        (modal).modal('hide');
      }, delay);
    }

    //DESTRUYE MODAL AL CERRAR
    modal.on('hidden.bs.modal', function (e) {
      window.$(this).remove();
    })
  }

  (async function homeModalNew() {
    try {
      const homeModal = document.querySelector('[data-bs="modal"]')

      if (homeModal) {
        showModal(homeModal)
      }
      else {

        const res = await fetch('/sheet/modal-inicio/')
        if(res.status == 200) {
          const html = await res.text()
          let homeModalContent = html.trim()
          if (homeModalContent) {
            let homeModal = document.createElement('div')
            homeModal.innerHTML = homeModalContent
            homeModal = homeModal.firstElementChild
            document.body.appendChild(homeModal)
            showModal(homeModal)
          }
        }
      }

    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.error(error)
      }
      console.warn('%cNo hay plantilla "Modal > Inicio"')
    }
  })()
}
