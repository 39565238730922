import bsale from '../Bsale'

export function formatPrice(price, number = false): any {
  try {
    var currency = bsale.config.currency;
    var thousandSeparator: string = currency.thousandSeparator
      ? currency.thousandSeparator
      : ".";
    var decimalSeparator: string = currency.decimalSeparator
      ? currency.decimalSeparator
      : ",";
    // decimal si la moneda es round utiliza roundDecimal, sino utiliza decimal
    let decimal: number = currency.round
      ? currency.roundDecimals
      : currency.decimals;
    //formatea a numero y aproxima decimal, lo transforma en string y luego en array "2.99" = ["2", "99"]
    let num: any = parseFloat(price).toFixed(decimal).toString().split(".");
    //retorna simbolo                 + array[1] formateado con sepador de mil                     + (separador de decimal + decimal) sólo si hay decimal
    if (number) {
      //si se necesita formateado como numero  formatPrice(number, true)
      return Number(`${num[0]}${num[1] ? "." + num[1] : ""}`);
    } else {
      //si se necesita formateado como precio  formatPrice(number)
      return `${currency.symbol.trim()} ${num[0].replace(
        /\B(?=(\d{3})+(?!\d))/g,
        thousandSeparator
      )}${num[1] ? decimalSeparator + num[1] : ""}`;
    }
  } catch (e) {
    console.log(e);
  }
}


