import bsale from '../Bsale'
import { post } from "../Bsale/post"
import { Tracking } from '../Bsale/Tracking'
import { log } from './log'

declare global {
  interface Window {
    dataLayer?
    gtag?
    fbq?
  }
}

export interface EventDetail {
  gtm?: {
    event: string
    data: any
  }
  gtmGA4?: {
    event: string
    data: any
  }
  analytics?: {
    event: string
    data: any
  }
  GA4?: {
    event: string
    data: any
  }
  pixel?: {
    event: string
    event_id: string
    data: any
    user_data?: any
  }
}

export async function track(name: string, { gtm, gtmGA4, analytics, GA4, pixel }: EventDetail): void {

  console.log(`${bsale.config.gtm} => ${bsale.config.gtm ? true : false}`)
  // console.log(`${bsale.config.analytics} => ${bsale.config.analytics ? true : false}`)
  // console.log(`${bsale.config.pixel} => ${bsale.config.pixel ? true : false}`)

  //Se espera la carga de los script y se obtienen las configuraciones
  await Tracking.trackingLoaded
  let tracking_config = Tracking.config

  console.log("BSale config Google Analytics ", tracking_config.analytics);
  console.log("BSale config Facebook Pixel ", tracking_config.pixel);
  
  //Google Tag Manager
  if (typeof bsale.config.gtm === 'boolean' && bsale.config.gtm) {
    //soporte componente antiguo
    window.dataLayer.push({
      event: gtm.event,
      ...gtm.data
    })
  } else if (bsale.config.gtm) {
    //old

      window.dataLayer.push({
        event: gtm.event ,
        ...gtm.data
      })

    //new
    
    window.dataLayer.push({
      event: gtmGA4.event,
      ...gtmGA4.data
    })
    /*
    try {
      if (ga.getAll()) {
        // si existe ga es UA

        if(gtm.event){
          window.dataLayer.push({
            event: gtm.event ,
            ...gtm.data
          })
        }else{
          window.dataLayer.push({
            ...gtm.data
          })
        }
      }
    } catch (e) {
      console.log(e)
      //sino, es G-
      window.dataLayer.push({
        event: gtmGA4.event,
        ...gtmGA4.data
      })
    }*/
  }// end else gtm

  //Google Analytics 
  if (tracking_config.analytics) {
    switch (tracking_config.analytics.substring(0, 2)) {
      case 'UA':
        log(`Universal Analytics ${tracking_config.analytics}`)
        console.log('Universal Analytics', analytics)
        //Tracking UA navegador
        window.gtag('event', analytics.event, analytics.data)
        break;
      case 'G-':
        log(`GA4 ${tracking_config.analytics}`)
        console.log('GA4', GA4)
        //Tracking GA4 navegador
        window.gtag('event', GA4.event, GA4.data)
        //Tracking GA4 servidor
        window.gtag('get', tracking_config.analytics, 'client_id', async (client_id) => {
          GA4["credentials"] = {"client_id": client_id};
          let res = await post('/gateway/market_tracking', JSON.stringify({"integration": "analytics", "analytics": GA4}));
          console.log("Response market tracking ANALYTICS", res);
        })
        break;
      default:
        log(`Google Analytics no valido ${tracking_config.analytics}`);
        break;
    }
  }

  //Facebook Pixel
  if (tracking_config.pixel) {
    //Tracking navegador
    window.fbq('track', pixel.event, pixel.data , {eventID: pixel.event_id})
    //Añadir user data al pixel
    if (!pixel.user_data) {
      pixel.user_data = {}
    }
    pixel.user_data.user_agent = navigator.userAgent;
    pixel.user_data.credentials = window.fbq.getState().pixels;
    //Obtener IP info
    let ip_info = await fetch("https://ipinfo.io/json", {method: 'GET'})
    ip_info =  await ip_info.json()
    if (ip_info) {
      pixel.user_data.ip_address = ip_info["ip"];
      pixel.user_data.country = ip_info["country"];
      pixel.user_data.city = ip_info["city"];
    }
    //Tracking servidor
    let res = await post('/gateway/market_tracking', JSON.stringify({"integration":"pixel", pixel}));
    console.log("Response Facebook Pixel server tracking", res);
  }
  log(name, { gtm, gtmGA4, analytics, GA4, pixel })
}
