import Bsale from "../../Bsale";
import { formatPrice } from '../../util/formatPrice';

export function removeFromCart(cartItem, q) {
  q = q || cartItem.quantity
  return {
    gtm: {
      event: 'removeFromCart',
      data: {
        ecommerce: {
          remove: {
            products: [{
              id: cartItem.variant.sku,
              name: cartItem.variant.name,
              price: cartItem.variant.price,
              variant: cartItem.variant.title,
              quantity: q
            }]
          }
        }
      }
    },
    gtmGA4:{
      event: 'remove_from_cart',
      data:{
        ecommerce:{
          items:[{
            item_name: cartItem.variant.name,
            item_id: cartItem.variant.sku,
            price: cartItem.variant.priceUnit,
            quantity: q 
          }]
        }
      }
    },
    analytics: {
      event: 'remove_from_cart',
      data: {
        items: [{
          id: cartItem.variant.sku,
          name: cartItem.variant.name,
          variant: cartItem.variant.name,
          quantity: q,
          price: cartItem.variant.price
        }]
      }
    },
    GA4: {
      event: 'remove_from_cart',
      data: {
        transaction_id: Date.now().toString(),
        currency: Bsale.config.currency.isoCode,
        items: [{
          item_id: cartItem.variant.sku,
          item_name: cartItem.variant.name,
          discount: formatPrice(cartItem.variant.price - cartItem.variant.finalPrice, true),
          price: formatPrice(cartItem.variant.price, true),
          currency: Bsale.config.currency.isoCode,
          quantity: q,
          promotion_name: cartItem.variant.discountName || ""
        }],
        value: q * formatPrice(cartItem.variant.finalPrice, true)
      }
    },
    pixel: {
      event: 'RemoveFromCart',
      event_id: Date.now().toString(),
      data: {
        id: cartItem.variant.sku,
        name: cartItem.variant.name,
        quantity: q
      }
    }
  }
}