import { search } from "../../Metrics/events/search";

const header = document.querySelector('[data-bs="header"]');

export function dropdownHeight(){
  try {
    if (header && header.getAttribute('data-info') === 'sticky') {
      //funciona sólo si es sticky
      function calcDropdownHeight() {
        const head = document.head || document.getElementsByTagName('head')[0];

        let dropdownScroll = document.getElementById('dropdownScroll');
        let height = window.innerHeight - header.offsetHeight - header.offsetTop - 10;
        let newCss;

        if (!dropdownScroll) {
          dropdownScroll = document.createElement('style');
          dropdownScroll.id = 'dropdownScroll';
          head.appendChild(dropdownScroll);
        }

        height > 0 ? height : 0;

        newCss = `[data-bs="dropdown-menu"]{max-height: none;overflow-y:auto;}
                    @media screen and (min-width:576px){.navbar-expand-sm [data-bs="dropdown-menu"]{max-height: ${height}px;}}
                    @media screen and (min-width:768px){.navbar-expand-md [data-bs="dropdown-menu"]{max-height: ${height}px;}}
                    @media screen and (min-width:992px){.navbar-expand-lg [data-bs="dropdown-menu"]{max-height: ${height}px;}}
                    @media screen and (min-width:1200px){.navbar-expand-xl [data-bs="dropdown-menu"]{max-height: ${height}px;}}`

        dropdownScroll.innerText = newCss.replace(/(\r\n|\n|\r|\t)/gm, "");
      }


      //ejecución
      calcDropdownHeight();
      window.addEventListener('resize', calcDropdownHeight);
      window.addEventListener('scroll', calcDropdownHeight);
    }//if
  } catch (ex) {
    console.log(ex)
  }//try
}

export function stickyHeader() {
  if (header && header.getAttribute('data-info') === 'sticky') {
    const internetExplorer = navigator.appVersion.indexOf('Trident') > -1;
    //nueva
    if (!internetExplorer) {
      //const
      const headerLogo = document.querySelector('[data-bs="header.logo"]');
      const main = document.querySelector('main');

      //let
      //let headerHeight = header ? header.offsetHeight : 0;
      let headerLogoWidth = headerLogo ? headerLogo.offsetWidth : 0;

      function reduceLogo(boolean) {
        if (boolean) {
          headerLogo.style.width = headerLogoWidth * 0.5 + "px";
          headerLogo.style.height = "auto";
          headerLogo.style.transition = ".25s all ease";
        } else {
          headerLogo.style.width = headerLogoWidth + "px";
          headerLogo.style.height = "auto";
          headerLogo.style.transition = ".25s all ease";
        }

      }



      if (header !== null) {
        window.onscroll = (e) => {

          if (window.scrollY > header.offsetTop) {
            //header ->  class en sticky.scss
            header.classList.add("bs-sticky-header");
            //main
            main.style.marginTop = `${header.offsetHeight}px`;
            // sticky css variable
            main.style.setProperty('--sticky-margin-top', `${header.offsetHeight}px`);

            //header.logo
            if (headerLogo !== null) {
              reduceLogo(true);
            }
          } else {
            //header
            header.classList.remove("bs-sticky-header");
            //main
            main.style.marginTop = "0";
            // sticky css variable
            main.style.setProperty('--sticky-margin-top', '0');
            //header.logo
            if (headerLogo != null) {
              reduceLogo(false);
            }
          }
          if (header.offsetHeight < main.style.marginTop) {
            main.style.marginTop = `${header.offsetHeight}px`;
            // sticky css variable
            main.style.setProperty('--sticky-margin-top', `${header.offsetHeight}px`);
          }
        };
      }
      if (headerLogo != null) {
        reduceLogo(false);
      }
    }
  } 
}//stickyHeader()

export function headerSearch() {
  //EVITA que si el input del Search esta vacio se realice una busqueda
  const searchForm = document.querySelectorAll("[data-bs='search']");
  try{
    for(let i = 0; i< searchForm.length; i++){
      searchForm[i].addEventListener("submit", function(e){
        let searchInput = searchForm[i].getElementsByTagName("INPUT")[0]
        searchInput.value = searchInput.value.replace(/[()*{}\[\]]/g, '')
        if(searchInput.value === ''){
          e.preventDefault();
        }
      })
    }
  }catch(ex) {
    console.log(ex);
  }

  /*
  //variables
  console.log("headerSearch")
  const selector = $("[data-bs='search']");
  const input = selector.find("input");

  selector.on("submit", function (evt) {
    var search = $.trim(input.val())
    input.val(search);

    if (search == '') {
      evt.preventDefault();
    }
  })
  */
}

export function searchHidden() {
  /*
  oculta muestra y oculta barra de busqueda,
  para ocultar los botones usar d-{{breakpoint}}-none de bootstrap 
  ********************/
  const selector = document.querySelector('[data-bs="search"][data-info="hidden"]');
  if(selector !== null){
    const show = document.querySelectorAll('[data-bs="search.hidden"][data-info="show"]');
    const close = document.querySelectorAll('[data-bs="search.hidden"][data-info="close"]');
    //agrega clase
    selector.classList.add("bs-search-hidden")
    // si cambia el tamaño 
    window.addEventListener("resize", function(){
      if (window.outerWidth >= 1200) {
        selector.classList.remove("show")
      }
    })
    for(let i = 0; i < show.length; i++){
      show[i].addEventListener("click", function(){
        selector.classList.add("show");
        selector.querySelector("input").focus();
      })
    }
    
    for(let i = 0; i < close.length; i++){
      close[i].addEventListener("click", function(){
        selector.classList.remove("show");
      })
    }
    
  }//selector !== null
}
/*
export function searchHidden() {
  console.log("aparece");
  //oculta muestra y oculta barra de busqueda,
  //para ocultar los botones usar d-{{breakpoint}}-none de bootstrap 
  
  const header = document.querySelector('.bs-header')
  const selector = $('[data-bs="search"][data-info="hidden"]');
  const show = $('[data-bs="search.hidden"][data-info="show"]');
  const close = $('[data-bs="search.hidden"][data-info="close"]');
  //agrega clase
  selector.addClass("bs-search-hidden");
  // si cambia el tamaño 
  $(window).resize(function () {
    if (window.outerWidth >= 1200) {
      selector.removeClass("show")
    }
  })
  //agrega la clase a deja el input en focus
  show.on("click", function () {
    function isInside(e) {
      if (!header.contains(e.target) || e.target === close[0] || e.target.parentNode === close[0]) {
        selector.removeClass("show")
        document.removeEventListener('click', isInside)
      }
    }
    selector.addClass("show")
    selector.find("input").focus();
    document.addEventListener('click', isInside)
  })
  //cierra el buscador
  close.on("click", function () {
    selector.removeClass("show")
  })
}
*/


export function hoverHeader() {
  /*
  function toggleDropdown (e) {
    const dropdown = $(e.target).closest('.dropdown');
    const menu = $('.dropdown-menu', dropdown);
    setTimeout(function(){
      const shouldOpen = e.type !== 'click' && dropdown.is(':hover');
      menu.toggleClass('show', shouldOpen);
      dropdown.toggleClass('show', shouldOpen);
      $('[data-toggle="dropdown"]', dropdown).attr('aria-expanded', shouldOpen);
    }, e.type === 'mouseleave' ? 300 : 0);
  }
  
  $('body')
    .on('mouseenter mouseleave','.dropdown',toggleDropdown)
    .on('click', '.dropdown-menu a', toggleDropdown);
  
  /* not needed, prevents page reload for SO example on menu link clicked */
  //$('.dropdown a').on('click tap', e => e.preventDefault())

}

export function dropdownMultiLevel() {

  $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
    var $parent   = $(this).closest(".dropdown")
    var $subMenu  = $(this).next('.dropdown-menu');

    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
    }
    
    $subMenu.toggleClass('show');
    $parent.toggleClass('show')
    
    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
      $('.dropdown-menu .show').removeClass('show');
      $parent.removeClass('show')
    });
  
    return false;
  });

}