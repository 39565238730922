export function createModal(content) {
  const modal = document.createElement("div")
  modal.classList.add("modal")
  modal.innerHTML = `
    <div class="modal-dialog">
      <div class="modal-content">
        ${content}
      </div>
    </div>`
  document.body.appendChild(modal)
  $(modal).modal("show")
  const close = () => {
    $(modal).modal("hide")
    document.body.removeChild(modal)
  }
  return [modal, close]
}
