
import { formatPrice } from '../../util/formatPrice'
// facebook https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/
export function purchase(purchaseInfo) {
  console.log(purchaseInfo)
  return {
    gtm: {
      event: 'purchase',
      data: {
        ecommerce: {
          purchase: {
            actionField: {
              id: purchaseInfo.numberDocumentTax,
              revenue: formatPrice(purchaseInfo.total, true),
              shipping: formatPrice(purchaseInfo.shippingCost, true) || 0
            },
            products: purchaseInfo.detail.map(function (variant) {
              return {
                id: variant.sku,
                name: variant.name,
                variant: variant.name,
                quantity: variant.quantity,
                price: variant.finalPrice
              }
            })
          }
        }
      }
    },
    gtmGA4:{
      event: 'purchase',
      data:{
        ecommerce:{
          transaction_id: purchaseInfo.numberDocumentTax,
          value: formatPrice(purchaseInfo.total, true),
          shipping: formatPrice(purchaseInfo.shippingCost, true),
          currency: purchaseInfo.currency,
          items: purchaseInfo.detail.map(function (variant) {
            return {
              item_id: variant.sku,
              item_name: variant.name,
              item_variant: variant.name,
              price: formatPrice(variant.finalPrice, true),
              quantity: variant.quantity
            }
          })
        }
      }
    },
    analytics: {
      event: 'purchase',
      data: {
        transaction_id: purchaseInfo.numberDocumentTax,
        currency: purchaseInfo.currency,
        value: formatPrice(purchaseInfo.total, true),
        items: purchaseInfo.detail.map(function (variant) {
          return {
            id: variant.sku,
            name: variant.name,
            variant: variant.name,
            quantity: variant.quantity,
            price: variant.finalPrice
          }
        })
      }
    },
    GA4:{
      event: 'purchase',
      data: {
        transaction_id: purchaseInfo.numberDocumentTax,
        currency: purchaseInfo.currency,
        shipping: formatPrice(purchaseInfo.shippingCost, true),
        value: formatPrice(purchaseInfo.total, true),
        items: purchaseInfo.detail.map(function (variant) {
          return {
            item_id: variant.sku,
            item_name: variant.name,
            item_variant: variant.name,
            price: variant.finalPrice,
            currency: purchaseInfo.currency,
            quantity: variant.quantity
          }
        })
      }
    },
    pixel: {
      event: 'Purchase',
      event_id: Date.now().toString(),
      data: {
        currency: purchaseInfo.currency,
        value: formatPrice(purchaseInfo.total, true),
        content_ids: purchaseInfo.detail.map(function (variant) {
          return variant.sku
        }),
        content_type: 'product_group',
        contents: purchaseInfo.detail.map(function (variant) {
          return {
            id: variant.sku,
            quantity: variant.quantity,
            item_price: variant.finalPrice
          }
        })
      },
      user_data: {
        em: purchaseInfo.email, 
        ph: purchaseInfo.phone
      }
    }
  }
}
