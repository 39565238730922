import Bsale from "../../Bsale";
import { formatPrice } from '../../util/formatPrice';


export function addToCart(cartItem, q) {
  console.log(cartItem)
  q = q || cartItem.quantity
  return {
    gtm: {
      event: 'addToCart',
      data: {
        ecommerce: {
          currencyCode: Bsale.config.currency.isoCode,
          add: {
            products: [{
              id: cartItem.variant.sku,
              name: cartItem.variant.name,
              price: cartItem.variant.priceUnit,
              variant: cartItem.variant.title,
              quantity: q
            }]
          }
        }
      }
    },
    gtmGA4:{
      event: 'add_to_cart',
      data:{
        ecommerce:{
          items:[{
            item_name: cartItem.variant.name,
            item_id: cartItem.variant.sku,
            price: cartItem.variant.priceUnit,
            quantity: q 
          }]
        }
      }
    },
    analytics: {
      event: 'add_to_cart',
      data: {
        items: [{
          id: cartItem.variant.sku,
          name: cartItem.variant.name,
          variant: cartItem.variant.title,
          quantity: q,
          price: cartItem.variant.priceUnit
        }]
      }
    },
    GA4:{
      event: 'add_to_cart',
      data:{
        transaction_id: Date.now().toString(),
        currency: Bsale.config.currency.isoCode,
        items:[{
          item_id: cartItem.variant.sku, 
          item_name: cartItem.variant.name,
          discount: formatPrice(cartItem.variant.price - cartItem.variant.finalPrice, true),
          price: formatPrice(cartItem.variant.price,true),
          currency: Bsale.config.currency.isoCode,
          quantity: q,
          promotion_name: cartItem.variant.discountName || ""
        }],
        value: q*formatPrice(cartItem.variant.finalPrice,true)
      }
    },
    pixel: {
      event: 'AddToCart',
      event_id: Date.now().toString(),
      data: {
        content_ids: [cartItem.variant.sku],
        content_type: 'product',
        content_name: cartItem.variant.name,
        value: cartItem.variant.priceUnit,
        currency: Bsale.config.currency.isoCode,
        contents: [{
          id: cartItem.variant.sku,
          quantity: q,
          item_price: cartItem.variant.priceUnit
        }]
      }
    }
  }
}

